  
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    height: 100% !important;
    width: 100%;
  }
  
  
  /* new styles added */
  .swiper-button {
    display: flex;
    z-index: 10;
    cursor: pointer;
  }
  .swiper-button svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  .image-swiper-button-prev {
    right: 30px;
    top: 0;
  }
  .image-swiper-button-next {
    right: 5px;
    top: 0;
  }
  .swiper-button-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  






   /* new styles added */
   .swiper-button1 {
    display: flex;
    z-index: 10;
    cursor: pointer;
  }
  .swiper-button1 svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  .image-swiper-button-prev1 {
    right: 30px;
    top: 0;
  }
  .image-swiper-button-next1 {
    right: 5px;
    top: 0;
  }
  .swiper-button-disabled1 {
    opacity: 0.5;
    pointer-events: none;
  }


  
   /* new styles added */
   .swiper-button2 {
    display: flex;
    z-index: 10;
    cursor: pointer;
  }
  .swiper-button2 svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  .image-swiper-button-prev2 {
    right: 30px;
    top: 0;
  }
  .image-swiper-button-next2 {
    right: 5px;
    top: 0;
  }
  .swiper-button-disabled2 {
    opacity: 0.5;
    pointer-events: none;
  }
  

  .custom-pagination-selected{
    background-color: red !important;
  }
  
  .custom-pagination-item{
    background-color: orange !important;
  }

  
  
.ant-switch-checked{
    background:#00b3fa !important;
}

.MuiSlider-thumb{
    color: #fac119;
    border: white 4px solid
}

.MuiSlider-track{
    color: #fac119;
    height: 13px !important;
}

.MuiSlider-rail{
    color: #D9F5FE;
    height: 13px !important;
}

.MuiSlider-valueLabel{
    background-color: white !important;
    font-family: 'Poppins' ;
    box-shadow: 0px 0px 10px 0px #0000001A;
    color: #C9C9C9 !important;
    display: flex !important;
    transform: translateY(-100%) scale(1) !important;
}


@keyframes example {
  from {opacity: 0; margin-top: -50px;}
  to {opacity: 1;}
}

/* The element to apply the animation to */
.animation1 {
  animation-name: example;
  animation-duration: 0.3s;
}
