@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
.App {
  text-align: center;
  font-family: 'Poppins !important';
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* // my css ********* */

.react-slideshow-container .default-nav {
  display: none !important;
}

.Active-sidebare-element {
  background-color: #00b3fa !important;
  color: white !important;
  font-size: 100px !important;
}

/* Customize the scrollbar width, color, and other properties */
.scroll-container {
  overflow-y: scroll; /* Ensure vertical scrollbar is visible when needed */
  scrollbar-width: thin; /* Adjust the width as needed */
  transition: opacity 0.3s ease-in-out;

}

.hhh{
  transition: 0.9s ease-in-out !important;
}

/* Webkit-based browsers (e.g., Chrome) */
.scroll-container::-webkit-scrollbar {
  width: 3px; /* Adjust the width as needed */
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #0277a5; /* Change the scrollbar thumb color */
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #005b9f; /* Change the thumb color on hover */
}

.scroll-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Change the scrollbar track color */
}


.scroll-container2::-webkit-scrollbar {
  width: 3px; /* Adjust the width as needed */
}

.scroll-container2::-webkit-scrollbar-thumb {
  background-color: #5b5e5f; /* Change the scrollbar thumb color */
}

.scroll-container2::-webkit-scrollbar-thumb:hover {
  background-color: #005b9f; /* Change the thumb color on hover */
}

.scroll-container2::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Change the scrollbar track color */
}





.scroll-container3::-webkit-scrollbar {
  width: 3px; /* Adjust the width as needed */
}

.scroll-container3::-webkit-scrollbar-thumb {
  background-color: #ffca1b; /* Change the scrollbar thumb color */
}

.scroll-container3::-webkit-scrollbar-thumb:hover {
  background-color: #ffca1b; /* Change the thumb color on hover */
}

.scroll-container3::-webkit-scrollbar-track {
  background-color: #f1f1f100; /* Change the scrollbar track color */
}



/* //scroll 2 */

/* Webkit-based browsers (e.g., Chrome) */
.selectQuestions::-webkit-scrollbar {
  width: 7px; /* Adjust the width as needed */
}

.selectQuestions::-webkit-scrollbar-thumb {
  background-color: #D1D1D1; /* Change the scrollbar thumb color */
  border-radius: 7px;

}

.selectQuestions::-webkit-scrollbar-thumb:hover {
  background-color: #D1D1D1; /* Change the thumb color on hover */
}

.selectQuestions::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Change the scrollbar track color */
}


/* Webkit-based browsers (e.g., Chrome) */
.qcmContainer{
  user-select: none;
}

/* Webkit-based browsers (e.g., Chrome) */
.qcmContainer::-webkit-scrollbar {
  width: 7px; /* Adjust the width as needed */
}

.qcmContainer::-webkit-scrollbar-thumb {
  background-color: #D1D1D1; /* Change the scrollbar thumb color */
  border-radius: 7px;

}

.qcmContainer::-webkit-scrollbar-thumb:hover {
  background-color: #D1D1D1; /* Change the thumb color on hover */
}

.qcmContainer::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Change the scrollbar track color */
}




/* // end scroll */



#tags-standard{
  font-family: 'Poppins' !important ;
  border-radius: 7px !important;
}

.MuiChip-label{
  font-family: 'Poppins' !important ;
}


.css-19kzrtu{
  padding: 0% !important;
}
.css-1gsv261{
  border-color: transparent !important;
}

.numberTextField .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border: none;
  text-align: center !important;
  width: 100%;
}

.numberTextField .MuiInputBase-root .MuiInputBase-input{
  text-align: center;
}

.numberTextField input[type="number"]::-webkit-inner-spin-button,
.numberTextField input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}


.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #00b3fa !important;
  border-color: #00b3fa !important;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
  background-color: white !important;
  border-color: #C9C9C9 !important;
  color: #C9C9C9 !important;
  
}

.NewQuestion .MuiFormHelperText-contained{
  background-color: white !important;
  margin: 0px !important;
  padding-right: 14px !important;
  padding-left: 10px;
  font-size: 15px;
  font-family: 'Poppins'
}

/* class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root" */

/* .ql-editor.ql-blank {
  min-height: 200px;
} */


.myImage:hover .subImage{
  display: flex;
}

.subImage{
  transition: display 0.5s ease;
}